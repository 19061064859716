/* flowtype/require-valid-file-annotation: off */
/* @jsx node */
// The `jsx node` comment at the top tells babel to transpile the jsx down to regular javascript.

import { getLogger, getSDKMeta, getCSPNonce } from '@paypal/sdk-client/src';
import { create, CONTEXT } from 'zoid/src';
import { isDevice, supportsPopups } from 'belter/src';
import { node, dom } from 'jsx-pragmatic/src';
import { Overlay, SpinnerPage } from '@paypal/common-components/src';
import { ZalgoPromise } from 'zalgo-promise/src';

import { getLocaleContent } from './template/content';
import { getZoidUrl, getTargetMeta, getRedirectionUrl } from '../common/utils';
import * as PopupBlockDetector from '../common/PopupBlockDetector';

// Create the zoid component
// Create a component definition, which will be loaded in both the parent and child windows.

function createZoidComponent() {
    const DonateCheckoutComponent = create({
        tag: 'donate-checkout',

        // The full url that will be loaded when your component is rendered, or a function returning the url.
        url: ({ props }) => {
            return getZoidUrl(props, '/donate/');
        },

        defaultContext: supportsPopups() ? CONTEXT.POPUP : CONTEXT.IFRAME,

        logger: getLogger(),

        // A function which should return a DOM element, rendered in place of the iframe element, or inside the popup window, as it loads.
        // The "doc" parameter is the target document of the new iframe or popup window and NOT the document of the parent page
        prerenderTemplate: ({ doc, props }) => {
            // Since this is inside the popup, we can assume that
            // if this function is called, then popup was opened successfully
            // This is added to handle scenarios where browser extensions
            // prevent the popups from opening
            PopupBlockDetector.popupOpenSuccess(donateCheckoutInstance);
            return (<SpinnerPage nonce={props.nonce} />).render(dom({ doc }));
        },

        // The dimensions for your component, in css - style units, with support for px or %.
        dimensions: isDevice()
            ? { width: '100%', height: '760px' }
            : { width: '612px', height: '760px' },

        // A mapping of prop name to prop settings. Used to do run-time validation and prop normalization.
        props: {
            env: {
                type: 'string',
                required: false
            },

            baseUrl: {
                type: 'string',
                required: false
            },

            sdkMeta: {
                type: 'string',
                queryParam: true,
                value: getSDKMeta
            },

            getPopupBridge: {
                type: 'function',
                required: false,
                value: () => {
                    return () => {
                        if (!window.popupBridge) {
                            return;
                        }

                        return {
                            nativeUrl: window.popupBridge.getReturnUrlPrefix(),
                            start: url => {
                                return new ZalgoPromise((resolve, reject) => {
                                    window.popupBridge.onComplete = (
                                        err,
                                        result
                                    ) => {
                                        return err
                                            ? reject(err)
                                            : resolve(result.queryItems);
                                    };
                                    window.popupBridge.open(url);
                                });
                            }
                        };
                    };
                }
            },

            nonce: {
                type: 'string',
                required: false,
                value: getCSPNonce
            },

            onComplete: {
                type: 'function',
                required: false
            },

            env: {
                type: 'string',
                required: false
            },

            targetMeta: {
                type: 'string',
                value: getTargetMeta,
                queryParam: 'targetMeta'
            },

            redirectParentWindow: {
                type: 'function',
                required: false,
                value: () => {
                    // This is called with { props, close, ... }
                    return url => {
                        window.parent.location.href = url;
                    };
                }
            },

            token: {
                type: 'string',
                required: false,
                queryParam: 'token'
            },
            business: {
                type: 'string',
                required: false,
                queryParam: 'business'
            },
            hosted_button_id: {
                type: 'string',
                required: false,
                queryParam: 'hosted_button_id'
            },
            amount: {
                type: 'string',
                required: false,
                queryParam: 'amount'
            },
            currency_code: {
                type: 'string',
                required: false,
                queryParam: 'currency_code'
            },
            no_recurring: {
                type: 'string',
                required: false,
                queryParam: 'no_recurring'
            },
            no_shipping: {
                type: 'string',
                required: false,
                queryParam: 'no_shipping'
            },
            no_note: {
                type: 'string',
                required: false,
                queryParam: 'no_note'
            },
            cn: {
                type: 'string',
                required: false,
                queryParam: 'cn'
            },
            item_name: {
                type: 'string',
                required: false,
                queryParam: 'item_name'
            },
            item_number: {
                type: 'string',
                required: false,
                queryParam: 'item_number'
            },
            cancel_return: {
                type: 'string',
                required: false,
                queryParam: 'cancel_return'
            },
            notify_url: {
                type: 'string',
                required: false,
                queryParam: 'notify_url'
            },
            return: {
                type: 'string',
                required: false,
                queryParam: 'return'
            },
            cpp_header_image: {
                type: 'string',
                required: false,
                queryParam: 'cpp_header_image'
            },
            image_url: {
                type: 'string',
                required: false,
                queryParam: 'image_url'
            },
            invoice: {
                type: 'string',
                required: false,
                queryParam: 'invoice'
            },
            custom: {
                type: 'string',
                required: false,
                queryParam: 'custom'
            },
            rm: {
                type: 'string',
                required: false,
                queryParam: 'rm'
            },
            night_phone_a: {
                type: 'string',
                required: false,
                queryParam: 'night_phone_a'
            },
            night_phone_b: {
                type: 'string',
                required: false,
                queryParam: 'night_phone_b'
            },
            night_phone_c: {
                type: 'string',
                required: false,
                queryParam: 'night_phone_c'
            },
            first_name: {
                type: 'string',
                required: false,
                queryParam: 'first_name'
            },
            last_name: {
                type: 'string',
                required: false,
                queryParam: 'last_name'
            },
            email: {
                type: 'string',
                required: false,
                queryParam: 'email'
            },
            address1: {
                type: 'string',
                required: false,
                queryParam: 'address1'
            },
            address2: {
                type: 'string',
                required: false,
                queryParam: 'address2'
            },
            city: {
                type: 'string',
                required: false,
                queryParam: 'city'
            },
            state: {
                type: 'string',
                required: false,
                queryParam: 'state'
            },
            zip: {
                type: 'string',
                required: false,
                queryParam: 'zip'
            },
            country: {
                type: 'string',
                required: false,
                queryParam: 'country'
            },
            button_source: {
                type: 'string',
                required: false,
                queryParam: 'button_source'
            },
            bn: {
                type: 'string',
                required: false,
                queryParam: 'bn'
            },
            on0: {
                type: 'string',
                required: false,
                queryParam: 'on0'
            },
            os0: {
                type: 'string',
                required: false,
                queryParam: 'os0'
            },
            on1: {
                type: 'string',
                required: false,
                queryParam: 'on1'
            },
            os1: {
                type: 'string',
                required: false,
                queryParam: 'os1'
            }
        },

        containerTemplate: ({
            context,
            close,
            focus,
            doc,
            event,
            frame,
            prerenderFrame
        }) => {
            // const {
            //     locale: { lang },
            // } = props;
            // const content = containerContent[lang];

            // This value is all small case in mobile
            // Eg: en-US
            const locale = (navigator && navigator.language) || ''; // eslint-disable-line compat/compat

            let country = locale.split('-')[1] || 'US';
            country = country.toUpperCase();

            let language = locale.split('-')[0] || 'en';
            language = language.toLowerCase();

            const contentStrings = getLocaleContent(country, language);

            return (
                <Overlay
                    context={context}
                    close={close}
                    focus={focus}
                    event={event}
                    frame={frame}
                    prerenderFrame={prerenderFrame}
                    content={contentStrings}
                />
            ).render(dom({ doc }));
        }
    });

    return DonateCheckoutComponent;
}

let zoidInstance;
let donateCheckoutInstance;

export function getDonateCheckoutComponent() {
    // This instance has to be created on load
    // on both the parent and child windows
    // Only then window.xprops will be available
    if (!zoidInstance) {
        zoidInstance = createZoidComponent();
        zoidInstance();
    }

    return function(props) {
        // Reusing the instance does not work
        // The popup will flash (open and immediately close)
        // if (donateCheckoutInstance) {
        //     return donateCheckoutInstance;
        // }
        donateCheckoutInstance = zoidInstance(props);
        donateCheckoutInstance.getRedirectionUrl = getRedirectionUrl(props);

        // Save the original renderTo function
        donateCheckoutInstance.originalRenderTo =
            donateCheckoutInstance.renderTo;

        // This is the new render function.
        // This is required to make this a non breaking change
        donateCheckoutInstance.renderTo = renderPopupOrRedirect;
        // Also create a showCheckout function
        donateCheckoutInstance.showCheckout = renderPopupOrRedirect;

        donateCheckoutInstance.event.on('zoid-destroy', () => {
            console.log('zoid-destroy: EVENT_RECEIVED ');
            // donateCheckoutInstance.close();
        });
        donateCheckoutInstance.event.on('zoid-close', () => {
            console.log('zoid-close: EVENT_RECEIVED ');
            // donateCheckoutInstance.close();
        });
        return donateCheckoutInstance;
    };
}

function renderPopupOrRedirect() {
    let redirectionUrl = donateCheckoutInstance.getRedirectionUrl();
    PopupBlockDetector.init(donateCheckoutInstance);

    // IE Check
    // If InternetExplorer then redirect to full screen mode
    if (document.documentMode) {
        window.location.href = redirectionUrl;
        return;
    }

    try {
        let zalgoPromise = donateCheckoutInstance.originalRenderTo.apply(
            null,
            arguments
        );

        zalgoPromise.then((p1, p2) => {
            console.log(
                'Popup opened successfully and Donate has fully loaded.'
            );
        });

        if (
            zalgoPromise &&
            zalgoPromise.error &&
            zalgoPromise.error.name === 'PopupOpenError'
        ) {
            // Log this to server side
            console.log('zalgoPromise.error ', zalgoPromise.error);
            window.location.href = redirectionUrl;
            return;
        } else if (
            zalgoPromise &&
            zalgoPromise.error &&
            zalgoPromise.error.name
        ) {
            // Log this to server side
            console.log('zalgoPromise.error ', zalgoPromise.error);
            window.location.href = redirectionUrl;
            return;
        }

        setTimeout(() => {
            if (!PopupBlockDetector.isPopupOpen(donateCheckoutInstance)) {
                // Popup not opened after 500 miliseconds
                // Most probably popup got blocked
                // Try a redirection
                window.location.href = redirectionUrl;
                return;
            }
        }, 500);
    } catch (error) {
        console.log('error ', error);
        // Log this
    }
}
