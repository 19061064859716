/* flowtype/require-valid-file-annotation: off */
/** @jsx node */
// The `jsx node` comment at the top tells babel to transpile the jsx down to regular javascript.
// http://localhost:1337/demo/donatebutton/iframe/index.htm
import { getLogger, getClientID, getPayPalDomainRegex, getSDKMeta, getClientAccessToken, getCorrelationID, getPayPalDomain } from '@paypal/sdk-client/src';
import { ZalgoPromise } from 'zalgo-promise/src';
import { create } from 'zoid/src';
import { node, dom } from 'jsx-pragmatic/src';

import { PrerenderedDonateButton } from './prerender';
import { getTargetMeta } from '../common/utils';

// API DOC
// https://github.com/krakenjs/zoid/blob/master/docs/api.md

// Create a component definition, which will be loaded in both the parent and child windows.


export const Button = create({
        tag: 'donate-button',
        
        // The full url that will be loaded when your component is rendered, or a function returning the url.
        url: ({ props }) => { // eslint-disable-line no-unused-vars
            if (props.baseUrl) {
                return `${props.baseUrl}/donate/smart/buttons`
            }
            const donateButtonUrl = `${ getPayPalDomain() }${ __PAYPAL_CHECKOUT__.__URI__.__DONATE_BUTTON__ }`;
            console.log('donateButtonUrl ', donateButtonUrl);
            return donateButtonUrl;

        },

        getPageUrl: {
            type:  'function',
            value: () => {
                return () => window.location.href;
            }
        },
        
        // domain: getPayPalDomainRegex(),

        // The dimensions for your component, in css - style units, with support for px or %.

        dimensions: {
            width:        '150px',
            height:       '50px'
        },

        autoResize: {
            width:        false,
            height:       true
        },

        logger: getLogger(),

        // A function which should return a DOM element, rendered in place of the iframe element, or inside the popup window, as it loads.
        // The "doc" parameter is the target document of the new iframe or popup window and NOT the document of the parent page
        prerenderTemplate: ({ state, props, doc }) => {

            return (
                <PrerenderedDonateButton
                    nonce={ props.nonce }
                    props={ props }
                    onRenderCheckout={ ({ win }) => {
                        state.prerenderDetails = { win };
                    } }
                />
            ).render(dom({ doc }));
        },


        // A mapping of prop name to prop settings. Used to do run-time validation and prop normalization.
        props: {
            sdkMeta: {
                type:        'string',
                queryParam:  true,
                value:       getSDKMeta
            },

            getPopupBridge: {
                type:     'function',
                required: false,
                value:    () => {
                    return () => {
                        if (!window.popupBridge) {
                            return;
                        }

                        return {
                            nativeUrl: window.popupBridge.getReturnUrlPrefix(),
                            start:     (url) => {
                                return new ZalgoPromise((resolve, reject) => {
                                    window.popupBridge.onComplete = (err, result) => {
                                        return err ? reject(err) : resolve(result.queryItems);
                                    };
                                    window.popupBridge.open(url);
                                });
                            }
                        };
                    };
                }
            },

            // clientID: {
            //     type:       'string',
            //     value:      getClientID,
            //     queryParam: true,
            //     required:   false,
            // },

            // clientAccessToken: {
            //     type:       'string',
            //     required:   false,
            //     queryParam: true,
            //     value:      getClientAccessToken
            // },

            // correlationID: {
            //     type:       'string',
            //     required:   false,
            //     value:      getCorrelationID
            // },

            onComplete: {
                type:       'function',
                required:   false
            },

            env: {
                type:       'string',
                required:   false
            },

            baseUrl: {
                type:       'string',
                required:       false
            },

            // This is in the Buttons component
            // env: {
            //     type:       'string',
            //     queryParam: true,
            //     value:      getEnv
            // },

            targetMeta: {
                type: 'string',
                value: getTargetMeta,
                queryParam: 'targetMeta'
            },

            hostedButtonId: {
                type:       'string',
                required:   false,
                queryParam: 'hosted_button_id'
            },

            token: {
                type:        'string',
                required:     false,
                queryParam: 'token'
            }
        }

        // defaultContext: __DEFAULT_CONTEXT__,
    
    });
