/* eslint import/no-commonjs: off */

import { getPayPalDomain } from '@paypal/sdk-client/src';
import { extendUrl } from 'belter/src';
import { getSDKMeta } from '@paypal/sdk-client/src';
import { allowedButtonParams } from './buttonParams';

export function getTargetMeta() {
    const targetMeta = {
        zoidVersion: __ZOID__.__VERSION__, // eslint-disable-line no-undef
        target: __PAYPAL_CHECKOUT__.__TARGET__, // eslint-disable-line no-undef
        sdkVersion: __PAYPAL_CHECKOUT__.__SDKVERSION__ // eslint-disable-line no-undef
    };

    return window.btoa(JSON.stringify(targetMeta));
}

/**
 * Creates zoid url
 * @param {object} props - props object from zoid.create options' methods containing 'env' property
 * @param {string} extension - (optional) string to extend your base url
 */
export function getZoidUrl({ env, baseUrl }, extension = '') {
    if (baseUrl) {
        return `${baseUrl}${extension}`;
    }

    switch (env) {
        case 'production':
            baseUrl = `https://www.paypal.com${extension}`;
            break;
        case 'sandbox':
            baseUrl = `https://www.sandbox.paypal.com${extension}`;
            break;
        case 'stage':
            baseUrl = `https://www.msmaster.qa.paypal.com${extension}`;
            break;
        default:
            baseUrl = `${getPayPalDomain()}${
                __PAYPAL_CHECKOUT__.__URI__.__DONATE_CHECKOUT__
            }`;
            console.log('donateCheckoutUrl ', baseUrl);
            break;
    }

    return baseUrl;
}

/**
 * Get the full URL to donatenodeweb.
 * This URL is required for doing full page redirection when popups are blocked
 */
export function getRedirectionUrl(props) {
    return function() {
        let donateCheckoutUrl = getZoidUrl(props, '/donate/');

        donateCheckoutUrl = extendUrl(donateCheckoutUrl, {
            query: getAllowedParams(props)
        });

        return donateCheckoutUrl;
    };
}

export function getAllowedParams(obj) {
    // 1. Get all allowed params
    const params = Object.keys(obj)
        .filter(function (k) {
            return obj[k] != null && allowedButtonParams.includes(k);
        })
        .reduce(function (acc, k) {
            acc[k] = obj[k];
        return acc;
    }, {});

    // 2. Add token required by GN flows
    if (obj.token) {
        params.token = obj.token;
    }

    // 3. Add SDK related params
    params.targetMeta = getTargetMeta();
    params.sdkMeta = getSDKMeta();

    return params;
}

