/* @flow */


// BEFORE
// import { DonateCheckoutComponent } from '../zoid/donate-checkout/component';
// 
// export const DonateCheckout = {
//     __get__: () => {
//         return DonateCheckoutComponent;
//     }
// };
// 
// 
// window.PayPal = window.PayPal || {};
// window.PayPal.Donation = window.PayPal.Donation || {};
// window.PayPal.Donation.Checkout = DonateCheckout.__get__();
// 
// 
// window.Donation = window.Donation || {};
// window.Donation.Checkout = DonateCheckout.__get__();




// AFTER
import { getDonateCheckoutComponent } from '../zoid/donate-checkout/componentV2';

export const DonateCheckout = {
    __get__: () => {
        return getDonateCheckoutComponent();
    }
};

window.PayPal = window.PayPal || {};
window.PayPal.Donation = window.PayPal.Donation || {};
window.PayPal.Donation.Checkout = DonateCheckout.__get__();


window.Donation = window.Donation || {};
window.Donation.Checkout = DonateCheckout.__get__();
