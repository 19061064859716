/* @flow */
/* eslint max-lines: 0 */

import { LANG } from '@paypal/sdk-constants/src';

type ContentMap = {
    [$Values<typeof LANG>]: {|
        windowMessage?: string,
        continueMessage?: string
    |}
};

const containerContent: ContentMap = {
    ar: [
        {
            countries: [],
            content: {
                windowMessage:
                    'هل تواجه صعوبة في مشاهدة متصفح PayPal الآمن؟ سنساعدك في إعادة فتح النافذة لاستكمال تبرعك.',
                continueMessage: 'انقر للمتابعة'
            }
        }
    ],
    bg: [
        {
            countries: [],
            content: {
                continueMessage: 'Щракнете, за да продължите',
                windowMessage:
                    'Не виждате ли защитения браузър на PayPal? Ще ви помогнем да отворите отново прозореца, за да завършите дарението си.'
            }
        }
    ],
    cs: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Nezobrazuje se vám bezpečný prohlížeč PayPal? Pomůžeme vám okno znovu otevřít, abyste mohli příspěvek dokončit.',
                continueMessage: 'Kliknutím pokračujte'
            }
        }
    ],
    da: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Kan du ikke se PayPals sikre browser? Vi hjælper dig med at genåbne vinduet, så du kan betale.',
                continueMessage: 'Klik for at fortsætte'
            }
        }
    ],
    de: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Das PayPal-Fenster wird nicht angezeigt? Wir helfen Ihnen, das Fenster erneut zu öffnen, um Ihre Spende abzuschließen.',
                continueMessage: 'Weiter'
            }
        }
    ],
    el: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Δεν βλέπετε το ασφαλές πρόγραμμα περιήγησης PayPal; Θα σας βοηθήσουμε να επανεκκινήσετε το παράθυρο για να ολοκληρώσετε τη δωρεά σας.',
                continueMessage: 'Πατήστε για να συνεχίσετε'
            }
        }
    ],
    en: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Don’t see the secure PayPal browser? We’ll help you re-launch the window to complete your donation.',
                continueMessage: 'Click to Continue'
            }
        },
        {
            countries: ['AU', 'GB', 'IN', 'SG'],
            content: {
                continueMessage: 'Click to Continue',
                windowMessage:
                    'Can’t see the secure PayPal browser? We’ll help you re-launch the window to complete your donation.'
            }
        }
    ],
    es: [
        {
            countries: [],
            content: {
                windowMessage:
                    '¿No ve el símbolo de navegación segura de PayPal? Le ayudaremos a abrir de nuevo la ventana para completar su donación.',
                continueMessage: 'Haga clic para continuar'
            }
        },
        {
            countries: ['ES'],
            content: {
                continueMessage: 'Haz clic para continuar',
                windowMessage:
                    '¿No ves el símbolo de navegación segura de PayPal? Te ayudaremos a abrir de nuevo la ventana para completar el donativo.'
            }
        },
        {
            countries: ['MX'],
            content: {
                continueMessage: 'Haga clic para continuar',
                windowMessage:
                    '¿No ve el navegador seguro de PayPal? Le ayudaremos a abrir de nuevo la ventana para completar su donación.'
            }
        }
    ],
    et: [
        {
            countries: [],
            content: {
                continueMessage: 'Jätkamiseks klõpsake',
                windowMessage:
                    'Kas te ei näe turvalist PayPali brauserit? Aitame teil annetuse lõpuleviimiseks akna uuesti avada.'
            }
        }
    ],
    fi: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Etkö näe suojattua PayPal-selainta? Autamme avaamaan ikkunan uudelleen lahjoituksen viimeistelyä varten.',
                continueMessage: 'Jatka klikkaamalla'
            }
        }
    ],
    fr: [
        {
            countries: [],
            content: {
                continueMessage: 'Cliquer pour continuer',
                windowMessage:
                    'Vous ne voyez pas le navigateur sécurisé PayPal ? Nous allons vous aider à relancer la fenêtre pour effectuer votre don.'
            }
        },
        {
            countries: ['CA'],
            content: {
                continueMessage: 'Cliquer pour continuer',
                windowMessage:
                    'Vous ne voyez pas le navigateur PayPal sécurisé ? Nous vous aiderons à relancer la fenêtre pour effectuer votre don.'
            }
        }
    ],
    he: [
        {
            countries: [],
            content: {
                windowMessage:
                    'אינך רואה את דפדפן PayPal המאובטח? אנו נעזור לך לפתוח מחדש את החלון כדי להשלים את התרומה שלך.',
                continueMessage: 'יש ללחוץ כדי להמשיך'
            }
        }
    ],
    hu: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Nem látja a biztonságos PayPal-böngészőt? Segítünk újra betölteni az ablakot, hogy befejezhesse az adományozást.',
                continueMessage: 'A folytatáshoz kattintson ide'
            }
        }
    ],
    id: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Peramban PayPal yang aman tidak terlihat? Kami akan membantu menampilkan ulang jendelanya untuk menyelesaikan donasi Anda.',
                continueMessage: 'Klik untuk Melanjutkan'
            }
        }
    ],
    it: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Non vedi il browser sicuro di PayPal? Ti aiuteremo a riavviare la pagina per completare la donazione.',
                continueMessage: 'Clicca per continuare'
            }
        }
    ],
    ja: [
        {
            countries: [],
            content: {
                windowMessage:
                    'セキュアなPayPalブラウザが表示されない場合は、ウィンドウを再起動して、寄付を完了できるようお手伝いいたします。',
                continueMessage: 'クリックして続行'
            }
        }
    ],
    ko: [
        {
            countries: [],
            content: {
                windowMessage:
                    '보안 PayPal 브라우저가 보이지 않으세요? 창을 다시 실행하여 기부를 완료할 수 있도록 도와드리겠습니다.',
                continueMessage: '클릭하여 계속'
            }
        }
    ],
    lt: [
        {
            countries: [],
            content: {
                continueMessage: 'Spauskite, jei norite tęsti',
                windowMessage:
                    'Nematote saugios „PayPal“ naršyklės? Padėsime iš naujo paleisti langą, kad užbaigtumėte aukojimo veiksmą.'
            }
        }
    ],
    lv: [
        {
            countries: [],
            content: {
                continueMessage: 'Noklikšķiniet, lai turpinātu',
                windowMessage:
                    'Vai neredzat drošo PayPal pārlūkprogrammu? Mēs palīdzēsim jums atkārtoti palaist logu, lai pabeigtu pirkumu.'
            }
        }
    ],
    ms: [
        {
            countries: [],
            content: {
                continueMessage: 'Klik untuk Meneruskan',
                windowMessage:
                    'Tidak melihat pelayar PayPal selamat? Kami akan membantu anda melancarkan semula tetingkap untuk melengkapkan derma anda.'
            }
        }
    ],
    no: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Ser du ikke den sikre PayPal-nettleseren? Vi åpner vinduet på nytt så du kan fullføre donasjonen.',
                continueMessage: 'Klikk for å fortsette'
            }
        }
    ],
    nl: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Zie je de beveiligde PayPal-browser niet? We helpen je het venster opnieuw te openen om je donatie te voltooien.',
                continueMessage: 'Klik op Doorgaan'
            }
        }
    ],
    pl: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Nie widzisz bezpiecznej przeglądarki PayPal? Pomożemy Ci ponownie otworzyć to okno, aby dokończyć przekazywanie darowizny.',
                continueMessage: 'Kliknij, aby kontynuować'
            }
        }
    ],
    pt: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Não vê a indicação de navegador seguro PayPal? Vamos ajudar a reabrir a janela para poder concluir o seu donativo.',
                continueMessage: 'Clicar para continuar'
            }
        },
        {
            countries: ['BR'],
            content: {
                continueMessage: 'Clique para Continuar',
                windowMessage:
                    'Não está vendo o navegador seguro do PayPal? Ajudaremos você a abrir a janela novamente para concluir a doação.'
            }
        }
    ],
    ro: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Nu vezi browser-ul securizat PayPal? Te vom ajuta să lansezi din nou fereastra pentru a finaliza donația.',
                continueMessage: 'Dă clic pentru a continua'
            }
        }
    ],
    ru: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Не отображается безопасная страница PayPal в браузере? Мы поможем вам повторно загрузить окно, чтобы отправить ваше пожертвование.',
                continueMessage: 'Нажмите сюда, чтобы продолжить'
            }
        }
    ],
    sk: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Nezobrazuje sa vám zabezpečený prehliadač PayPal? Pomôžeme vám znova otvoriť okno, aby ste mohli dokončiť príspevok.',
                continueMessage: 'Pokračujte kliknutím'
            }
        }
    ],
    sl: [
        {
            countries: [],
            content: {
                continueMessage: 'Kliknite za nadaljevanje',
                windowMessage:
                    'Ne vidite PayPalovega varnega brskalnika? Pomagali vam bomo znova zagnati okno za dokončanje donacije.'
            }
        }
    ],
    sv: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Ser du inte den säkra PayPal-webbläsaren? Vi hjälper dig att starta om fönstret för att slutföra din donation.',
                continueMessage: 'Klicka för att fortsätta'
            }
        }
    ],
    th: [
        {
            countries: [],
            content: {
                windowMessage:
                    'ถ้าคุณไม่เห็นเบราว์เซอร์ที่มีระบบความปลอดภัยของ PayPal เราจะช่วยคุณเปิดหน้าต่างอีกครั้งเพื่อบรจาคเงินให้เรียบร้อย',
                continueMessage: 'คลิกเพื่อดำเนินการต่อ'
            }
        }
    ],
    tl: [
        {
            countries: [],
            content: {
                continueMessage: 'I-click para Magpatuloy',
                windowMessage:
                    'Hindi nakikita ang secure na PayPal browser? Tutulungan ka naming i-launch ulit ang window para makumpleto ang donasyon mo.'
            }
        }
    ],
    vi: [
        {
            countries: [],
            content: {
                windowMessage:
                    'Bạn không thấy trình duyệt PayPal bảo mật? Chúng tôi sẽ giúp bạn mở lại cửa sổ để hoàn tất khoản quyên góp của bạn.',
                continueMessage: 'Nhấp vào để tiếp tục'
            }
        }
    ],
    zh: [
        {
            countries: [],
            content: {
                windowMessage:
                    '没有找到PayPal安全浏览器？我们将帮助您重启窗口以完成捐款。',
                continueMessage: '点击以继续'
            }
        },
        {
            countries: ['C2'],
            content: {
                continueMessage: '点击以继续',
                windowMessage:
                    '没有找到安全的PayPal浏览器？我们将帮助您重启窗口以完成捐款。'
            }
        },
        {
            countries: ['HK'],
            content: {
                continueMessage: '按一下繼續',
                windowMessage:
                    '看不到安全的 PayPal 瀏覽器視窗？我們會助你重新開啟視窗以完成捐款。'
            }
        },
        {
            countries: ['TW'],
            content: {
                continueMessage: '按一下並繼續',
                windowMessage:
                    '看不到安全連線的 PayPal 瀏覽器？我們將協助重新啟動視窗以完成你的捐款。'
            }
        }
    ]
};

export function getLocaleContent(country, language) {
    const contentArray = containerContent[language] || containerContent['en'];

    for (let i = 1; i < contentArray.length; i++) {
        if (contentArray[i].countries.indexOf(country) >= 0) {
            return contentArray[i].content; // if country has specialized content, return it
        }
    }

    return contentArray[0].content; // default content is in index 0
}
