import { allowedButtonParams } from '../common/buttonParams';

export const ButtonLocal = createLocalDonateButton();

export function createLocalDonateButton() {
    return function(props) {
        const comp = Component(props);
        const instance = comp.init();

        return instance;
    };
}

function Component(props) {
    const getValidQueryParams = () => {
        const allowedParamProps = {};
        for (const key in props) {
            if (allowedButtonParams.includes(key)) {
                allowedParamProps[key] = props[key].toString();
            }
        }
        return allowedParamProps;
    };
    // The zoid buttons use "init" and "instance"
    // So following same convention here as well
    const init = () => {
        const instance = {
            render: function(selector) {
                var defaultImage =
                    'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif';
                var buttonContainer = document.querySelector(selector);

                if (!buttonContainer) {
                    console.log(
                        'Donate Button Container not found for ',
                        selector
                    );
                    return;
                }

                var imageSrc = props?.image?.src || defaultImage;
                var imageTitle = props?.image?.title || '';
                var imageAlt = props?.image?.alt || '';

                var img = document.createElement('IMG');
                img.setAttribute('src', imageSrc);
                img.setAttribute('id', 'donate-button');
                img.setAttribute('style', 'cursor: pointer;');
                img.setAttribute('title', imageTitle);
                img.setAttribute('alt', imageAlt);

                const validQueryParams = getValidQueryParams();

                img.addEventListener('click', function(event) {
                    PayPal.Donation.Checkout({
                        env: props.env,
                        baseUrl: props.baseUrl,
                        token: props.token,
                        onComplete: props.onComplete,
                        ...validQueryParams
                    }).renderTo(window.parent);
                });

                buttonContainer.appendChild(img);
            }
        };
        return instance;
    };

    return {
        init
    };
}
