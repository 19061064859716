import * as DonateButtonLocal from '../donate-button-local';
import * as DonateButtonZoid from '../donate-button';

export const getDonateButtonManagerComponent = () => {
    return function(props) {
        console.log('DonateButtonManager props ', props);
        if (props.type && props.type === 'remote') {
            return DonateButtonZoid.Button.apply(this, arguments);
        }
        return DonateButtonLocal.ButtonLocal.apply(this, arguments);
    }    
}
