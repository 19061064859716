/* @flow */

// The exports from this file go to the final Donate SDK bundle
// Check webpack config file where this is used as entry

import { DonateCheckout } from './interface/donateCheckout';
import { DonateButton } from './interface/donateButtonManager';

export const Checkout = DonateCheckout.__get__();
export const Button = DonateButton.__get__();
