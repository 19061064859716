/* @flow */

import { getDonateButtonManagerComponent } from '../zoid/donate-button-manager/component';

export const DonateButton = {
    __get__: () => getDonateButtonManagerComponent()
};

window.PayPal = window.PayPal || {};
window.PayPal.Donation = window.PayPal.Donation || {};
window.PayPal.Donation.Button = DonateButton.__get__();

window.Donation = window.Donation || {};
window.Donation.Button = DonateButton.__get__();
