export const allowedButtonParams = [
    // Primary details
    'hosted_button_id',
    'business',
    'amount',
    'currency_code',

    // Donation options
    'no_recurring',
    'no_shipping',
    'no_note',
    'cn',

    // Program and Purpose
    'item_name',
    'item_number',

    // URLs
    'cancel_return',
    'notify_url',
    'return',

    // Logo image
    'cpp_header_image',
    'image_url',

    // Others
    'invoice',
    'custom',
    'rm',

    // Sender Details
    'night_phone_a',
    'night_phone_b',
    'night_phone_c',
    'first_name',
    'last_name',
    'email',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'country',


    // Source stuff
    'button_source',
    'bn',

    // donationDetailOptions
    'on0',
    'os0',
    'on1',
    'os1'

];