export function init(instance) {
    if (instance) {
        instance.popupOpened = undefined;
    }
}

export function popupOpenSuccess(instance) {
    if (instance) {
        instance.popupOpened = true;
    }   
}

export function isPopupOpen(instance) {
    return instance && instance.popupOpened;
}
