/* flowtype/require-valid-file-annotation: off */
/** @jsx node */

import { node, dom, type ChildType } from 'jsx-pragmatic/src';
import { popup, supportsPopups, writeElementToWindow } from 'belter/src';
import {
    assertSameDomain
} from 'cross-domain-utils/src';

export function PrerenderedDonateButton({
    nonce, // eslint-disable-line no-unused-vars
    onRenderCheckout
}) : ChildType {
    const handleClick = () => {
        if (supportsPopups()) {
            const win = assertSameDomain(
                popup('', {
                    width:    '200px',
                    height:   '100px'
                })
            );

            const doc = window.document;

            const spinner = (
                <div>PrerenderedDonateButton: This is from handleClick. TODO: Show SpinnerPage component</div>
            ).render(dom({ doc }));

            writeElementToWindow(win, spinner);

            onRenderCheckout({ win });
        } else {
            onRenderCheckout({});
        }
    };

    return (
        <html>
            <body style="margin: 0;">
                <div>
                    <input
                        onClick={ handleClick }
                        id="donate-button"
                        type="image"
                        src="https://www.paypal.com/en_US/i/btn/btn_donateCC_LG.gif"
                    />
                </div>
            </body>
        </html>
    );
}
